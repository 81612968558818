@import "mixins";
@import "color-palette";
@import "vars";
@import "breakpoints";

.NotFoundScreen {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 18px;
    border-top: 1px solid $color-border-catalog;
    padding: 16px;

    @media (max-width: $breakpoint-mobile-lg) {
        flex-direction: column;
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-size: 18px;
        line-height: 22px;
        font-weight: $font-weight-semibold;
        color: $color-primary-black;
    }

    &__description {
        font-size: 13px;
        line-height: 16px;
        font-weight: $font-weight-medium;
        color: $color-primary-black;
        margin: 10px 0;
    }

    &__options {
        font-weight: $font-weight-medium;
        font-size: 13px;
        line-height: 16px;
        color: $color-primary-black;
    }

    &__return {
        font-weight: $font-weight-semibold;
        font-size: 13px;
        line-height: 16px;
        color: $color-text-aqua;
    }
}
